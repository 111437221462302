import './stores.scss';

(() => {
  const iosLinks = document.querySelectorAll('.ga-iOS');
  const androidLinks = document.querySelectorAll('.ga-Android');

  if (gon.app_env === 'production') {
    iosLinks.forEach((link) => {
      link.addEventListener('click', () => {
        if (!gon.accepted_cookies) {
          return;
        }

        // Google Analytics event
        gtag('event', 'Clicked', {
          event_category: 'Stores',
          event_label: 'See in App Store',
        });

        // Facebook pixel tracking
        fbq('track', 'ViewContent', { content_name: 'App Store' });
      });
    });

    androidLinks.forEach((link) => {
      link.addEventListener('click', () => {
        if (!gon.accepted_cookies) {
          return;
        }

        // Google Analytics event
        gtag('event', 'Clicked', {
          event_category: 'Stores',
          event_label: 'See in Play Store',
        });

        // Facebook pixel tracking
        fbq('track', 'ViewContent', { content_name: 'Play Store' });
      });
    });
  }
})();
