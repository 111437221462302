import LazyLoad from 'vanilla-lazyload';
import 'bootstrap.native';

import './out.scss';
import setupCSRFToken from './setupCSRFToken';
import setupRedirects from './setupRedirects';

setupCSRFToken();
setupRedirects();

window.lazyLoadInstance = new LazyLoad({
  elements_selector: '.lazy',
});
