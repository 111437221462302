import './flashes.scss';

const closeButtons = document.querySelectorAll('button[data-dismiss="alert"]');

if (closeButtons.length) {
  closeButtons.forEach((button) =>
    button.addEventListener('click', () =>
      button.parentNode.classList.remove('slide-in')
    )
  );
}
