import './form.scss';
import '../email-suggestion/email-suggestion.scss';
import { isEmail } from '../../src/utils/validations';
import { checkEmail } from '../email-suggestion/email-suggestion';

(() => {
  const signUpForm = document.getElementById('ga-signUp');
  const signInForm = document.getElementById('ga-signIn');
  const invitationForm = document.getElementById('ga-acceptInvitation');
  const resetPasswordForm = document.getElementById('ga-resetPassword');
  const updatePasswordForm = document.getElementById('ga-updatePassword');
  const faqsSearchForm = document.getElementById('ga-faqsSearch');
  const resendInstrucForm = document.getElementById(
    'ga-resendConfirmationInstructions'
  );

  const formEmail = document.getElementById('session_email');
  const formPassword = document.getElementById('session_password');
  const formPasswordConfirm = document.getElementById(
    'session_password_confirmation'
  );

  const formPasswordToken = document.getElementById(
    'session_reset_password_token'
  );

  const formInvitationToken = document.getElementById(
    'session_invitation_token'
  );

  const formCheckbox = document.getElementById('session_cgu_param_accepted');

  function emailIsValid() {
    return formEmail.value && isEmail(formEmail.value);
  }

  function passwordIsValid() {
    return formPassword.value && formPassword.value.length >= 6;
  }

  function passwordConfirmIsValid() {
    return (
      formPasswordConfirm.value &&
      formPassword.value &&
      formPasswordConfirm.value === formPassword.value
    );
  }

  function cguChecked() {
    return formCheckbox.checked;
  }

  function signUpFormIsValid() {
    return emailIsValid() && passwordIsValid() && cguChecked();
  }

  function invitationFormIsValid() {
    return formInvitationToken.value && passwordIsValid() && cguChecked();
  }

  function signInFormIsValid() {
    return emailIsValid() && passwordIsValid();
  }

  function updatePasswordFormIsValid() {
    return (
      formPasswordToken.value && passwordIsValid() && passwordConfirmIsValid()
    );
  }

  function handleOnClickSuggestion(suggestion) {
    formEmail.value = suggestion;
  }

  if (formEmail) {
    formEmail.addEventListener('blur', () =>
      checkEmail(formEmail.value, formEmail, handleOnClickSuggestion)
    );
  }

  // Sends events to GA when submiting valid forms
  //
  if (gon.app_env === 'production') {
    if (signUpForm) {
      signUpForm.addEventListener('submit', () => {
        if (signUpFormIsValid() && gon.accepted_cookies) {
          gtag('event', 'Signed up', {
            event_category: 'Sign up',
            event_label: 'Signed up with email',
          });
          // Facebook pixel tracking
          fbq('track', 'CompleteRegistration', { content_name: 'Signed up' });
        }
      });
    }

    if (signInForm) {
      signInForm.addEventListener('submit', () => {
        if (signInFormIsValid() && gon.accepted_cookies) {
          gtag('event', 'Signed in', {
            event_category: 'Sign in',
            event_label: 'Signed in with email',
          });
        }
      });
    }

    if (invitationForm) {
      invitationForm.addEventListener('submit', () => {
        if (invitationFormIsValid() && gon.accepted_cookies) {
          gtag('event', 'Accepted', {
            event_category: 'Invitation',
            event_label: 'Accepted invitation',
          });
        }
      });
    }

    if (resetPasswordForm) {
      resetPasswordForm.addEventListener('submit', () => {
        if (emailIsValid() && gon.accepted_cookies) {
          gtag('event', 'Reset', {
            event_category: 'Password',
            event_label: 'Asked for a reset password token',
          });
        }
      });
    }

    if (resendInstrucForm) {
      resendInstrucForm.addEventListener('submit', () => {
        if (emailIsValid() && gon.accepted_cookies) {
          gtag('event', 'Resend', {
            event_category: 'Confirmation',
            event_label: 'Asked for a new confirmation email',
          });
        }
      });
    }

    if (updatePasswordForm) {
      updatePasswordForm.addEventListener('submit', () => {
        if (updatePasswordFormIsValid() && gon.accepted_cookies) {
          gtag('event', 'Updated', {
            event_category: 'Password',
            event_label: 'Updated password with token',
          });
        }
      });
    }

    if (faqsSearchForm) {
      faqsSearchForm.addEventListener('submit', () => {
        if (gon.accepted_cookies) {
          gtag('event', 'FAQ Search', {
            event_category: 'FAQ',
            event_label: 'FAQ Search',
          });
        }
      });
    }
  }
})();
