import mailcheck from '../../src/utils/mailcheck';
import './email-suggestion.scss';

const emailSuggestion = document.getElementById('email-suggestion-container');

function hideSuggestion() {
  if (emailSuggestion && !emailSuggestion.classList.contains('hidden')) {
    emailSuggestion.classList.add('hidden');
  }
}

function addEmailSuggestion(suggestion, parentDiv, handleOnClickSuggestion) {
  const suggestionBtn = document.getElementById('email-suggestion');
  const suggestionDomain = document.getElementById('email-suggestion-domain');
  const suggestionAddress = document.getElementById('email-suggestion-address');

  if (emailSuggestion && emailSuggestion.classList.contains('hidden')) {
    emailSuggestion.classList.remove('hidden');
  }

  if (suggestionBtn) {
    suggestionBtn.addEventListener('click', () => {
      hideSuggestion();
      handleOnClickSuggestion(suggestion.full);
    });
  }

  if (suggestionAddress) {
    suggestionAddress.textContent = `${suggestion.address}@`;
  }

  if (suggestionDomain) {
    suggestionDomain.textContent = suggestion.domain;
  }
}

export function checkEmail(value, parentDiv, handleOnClickSuggestion) {
  mailcheck.run({
    email: value,
    suggested: (suggest) => {
      addEmailSuggestion(suggest, parentDiv, handleOnClickSuggestion);
    },
    empty: () => {
      hideSuggestion();
    },
  });
}
