import axios from 'axios';

import './facebook-connect.scss';

const fbConnect = document.getElementById('fbConnect');
const path = window.location.href;
const signingUp =
  document.querySelector('.ga-facebook-signup') && gon.app_env === 'production';
const signingIn =
  document.querySelector('.ga-facebook-signin') && gon.app_env === 'production';

const authenticateUser = (response) => {
  axios
    .post('/users/omniauth/facebook', response)
    .then(() => {
      if (path.includes('settings')) {
        window.location.replace('/users/settings/edit');
      } else {
        window.location.replace('/');

        if (signingUp) {
          gtag('event', 'Signed up', {
            event_category: 'Sign up',
            event_label: 'Signed up with Facebook',
          });
        }
        if (signingIn) {
          gtag('event', 'Signed in', {
            event_category: 'Sign in',
            event_label: 'Signed in with Facebook',
          });
        }
      }
    })
    .catch(() => {
      if (path.includes('settings')) {
        window.location.replace('/users/settings/edit');
      } else {
        window.location.replace('/');
      }
    });
};

const displayConnectError = () => {
  const flash = document.getElementById('facebookError');
  flash.classList.remove('hidden');
  fbConnect.classList.remove('disabled');
};

const preventClick = (e) => e.preventDefault();

const enableFacebookButton = () => {
  fbConnect.classList.remove('disabled');
  fbConnect.removeEventListener('click', preventClick);
};

const disableFacebookButton = () => {
  fbConnect.classList.add('disabled');
  fbConnect.addEventListener('click', preventClick);
};

if (fbConnect) {
  window.fbAsyncInit = () => {
    FB.init({
      appId: gon.FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v2.8',
    });

    const loginToFacebook = () => {
      FB.login(
        (response) => {
          if (response.authResponse && response.status === 'connected') {
            authenticateUser(response);
          } else {
            displayConnectError();
            enableFacebookButton();
          }
        },
        { scope: 'email,public_profile', return_scopes: true }
      );
    };

    fbConnect.addEventListener(
      'click',
      (e) => {
        if (e.target.classList.contains('disabled')) {
          return;
        }
        disableFacebookButton();
        loginToFacebook();
      },
      false
    );
  };

  /* eslint-disable */
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/fr_FR/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
  /* eslint-enable */
}
