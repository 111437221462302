import './faq-vote.scss';

const votesButtons = document.querySelectorAll('#faq-vote a');

if (votesButtons.length) {
  const thanksCallback = () => {
    const votes = document.getElementsByClassName('votes')[0];
    votes.remove();

    const section = document.getElementById('faq-vote');
    const thanks = document.createElement('p');
    thanks.appendChild(document.createTextNode('Merci !'));
    section.appendChild(thanks);
  };

  votesButtons.forEach((button) => {
    button.href = button.dataset.href; // eslint-disable-line no-param-reassign
    button.addEventListener('click', thanksCallback);
  });
}
