import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

import './testimonials.scss';

const slider = document.querySelector('.slider');

if (slider) {
  tns({
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayHoverPause: true,
    controls: false,
  });
}
