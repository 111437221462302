import './iphone.scss';

const video = document.getElementById('preview');
const audioControl = document.querySelector('.audio-control');

if (video) {
  audioControl.addEventListener('click', () => {
    if (video.muted) {
      video.muted = false;
      audioControl.classList.remove('fa-volume-up');
      audioControl.classList.add('fa-volume-off');
    } else {
      video.muted = true;
      audioControl.classList.remove('fa-volume-off');
      audioControl.classList.add('fa-volume-up');
    }
  });
}
